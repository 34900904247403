import React from 'react';
import {  animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom'
import video from '../../assets/home.mp4'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { RiArrowDownDoubleFill } from "react-icons/ri";

const ImageSection = () => {

    useEffect(() => {
        AOS.init();
    }, [])
 
      
    const handleScroll = (e, id) => {
        e.preventDefault();
        const element = document.getElementById(id);
        if (element) {
            const offset = element.offsetTop - 20;
            window.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        }
    };
    
 
    return (
        <div className="">
            <div className='mainbg-outer' >
               
                <video className='background' id="bannerVideo" autoPlay loop muted playsInline>
                    <source src={video} type="video/mp4" />
                </video>

                <div className="slider_head text-center px-2" >
                    <h1 className='fs5-3 text-white'>Välkommen till Omni Telecom</h1>
                    <p className="fs10 text-white mb-3">Vi Förenklar, Förbättrar och Förvaltar era mobila lösningar</p>

                    <button className='btn btn-image btn-dark'><Link to='/contactUs' className='text-white'  >Kontakta oss</Link></button>
                   
                    
                </div>
                <Link className='fs-1 text-white arrow_absolute' to="#section02" onClick={(e) => handleScroll(e, '#section02')}>
                    <RiArrowDownDoubleFill />
                        </Link>

            </div>
        </div>
    )
}

export default ImageSection
