import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PrincipleModal from './PrincipleModal';
import img from '../../assets/welcome-1.jpg'
import img1 from '../../assets/welcome-2.jpg'
import img2 from '../../assets/welcome-3.jpg'
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const PrincipleDPA = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    return (
        <div className='overflow-hidden' id='#section02'>
            <div className="container my-6 " >
                <div className='row align-items-center'>
                    {/* <div className='col-lg-6 col-md-12 mb-4 mb-lg-0'>
                       
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            onAutoplayTimeLeft={onAutoplayTimeLeft}
                            className="mySwiper bg_img_welcome"
                        >
                            <SwiperSlide>
                                <div className=' py-3 p-3 h-100' >
                                    <img src={img} className=' w-80' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=' py-3 p-3 h-100' >
                                    <img src={img1} className=' w-80' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=' py-3 p-3 h-100' >
                                    <img src={img2} className=' w-80' />
                                </div>
                            </SwiperSlide>
                            
                           

                            <div className="autoplay-progress d-none" slot="container-end">
                                <svg viewBox="0 0 48 48" ref={progressCircle}>
                                    <circle cx="24" cy="24" r="20"></circle>
                                </svg>
                                <span ref={progressContent}></span>
                            </div>
                        </Swiper>
                    </div> */}
                    <div className='col-lg-12 col-md-12 mb-4 mb-lg-0'>
                        <div className='ps-lg-5' data-aos="fade-left" data-aos-easing="linear" data-aos-duration="500">
                            <h2 className="mb-3 text-center" >Välkommen till Omni Telecom</h2>
                            <p className='color_p text-center  mb-2'>Din partner för att förenkla, förbättra och förvalta era mobila lösningar.</p>
                            <p className='color_p text-center  mb-0'> Omni Telecom är dedikerat till att skapa mervärde för sina kunder genom att erbjuda kostnadsbesparingar och förbättra deras mobila- eller digitala upplevelser. Vi strävar alltid efter att erbjuda en kombination av båda, vilket gör oss till en pålitlig partner för våra kunder. Vår främsta målsättning är att etablera långsiktiga relationer som bygger på ömsesidigt förtroende och tillit.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <PrincipleModal />
        </div>

    )
}

export default PrincipleDPA
