import React, { useState, useEffect } from "react";
import contactImg from "../../assets/contact-uci.avif";
import { RiBuilding4Line } from "react-icons/ri";
import { MdOutlineEmail, MdOutlinePhoneInTalk } from "react-icons/md";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);

//   const [name, setName] = useState("");
//   const [business_name, setBusiness_name] = useState("");
//   const [phone, setNumber] = useState("");
//   const [email, setEmail] = useState("");
//   const [numberError, setNumberError] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [message, setMessage] = useState("");
//   const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(false);

//   const handleNumberChange = (e) => {
//     const inputValue = e.target.value;

//     setNumber(inputValue);

//     // Validate number
//     if (isNaN(inputValue)) {
//       setNumberError("Ange ett telefonnummer");
//     } else {
//       setNumberError("");
//     }
//   };

//   const handleEmailChange = (e) => {
//     const inputValue = e.target.value;
//     setEmail(inputValue);

//     // Validate email
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!emailPattern.test(inputValue)) {
//       setEmailError("Vänligen ange e-post");
//     } else {
//       setEmailError("");
//     }
//   };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // HTML tag validation
    const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;

    if (htmlTagRegex.test(value)) {
      setErr({ ...err, [`${name}Err`]: "HTML-taggar är inte tillåtna." });
    } else {
      setErr({ ...err, [`${name}Err`]: "" });
    }
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = { ...err };
    // let errors = {
    //    ...err
    //   };
   
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Checking for required fields
      if (!formState.email) errors.emailErr = "Vänligen ange e-post.";
      else if (!emailRegex.test(formState.email)) errors.emailErr = "Ogiltigt e-postformat.";
      if (!formState.phone) errors.phoneErr = "Ange ett telefonnummer.";
        console.log('Object.values(err)', Object.values(err))
        setErr(errors);
        const finalErr = Object.values(errors);
    const hasErrors = finalErr.some(error => error !== "");
if (hasErrors) {
   return;
  } else {
  
      // Setting error state
    //   let {name,}=formState
     
    const {name,business_name,phone,email,message}=formState;
    console.log('name,business_name,phone,email,message', name,business_name,phone,email,message)
    // if (!numberError && !emailError && phone && email && !messageError) {
    if (phone && email ) {
      setLoading(true); // Show loader

    //   setNumber("");
    //   setEmail("");

      // Make HTTP POST request to the PHP file
      fetch(
        "https://development81.shrinkcom.com/smtp_mailsss/send-mail-omni.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
            phone: phone,
            email: email,
            business_name: business_name,
            message: message,
            subject: "Contact Us",
          }),
        }
      )
        .then((response) => {
          setLoading(false); // Hide loader

          if (response.ok) {
            // Handle successful response
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          // Handle data if needed
          Swal.fire({
            text: "Tack! Vi återkommer snarast möjligt",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#1e2025",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        })
        .catch((error) => {
          setLoading(false); // Hide loader

          // Handle error
          console.error("There was a problem with the fetch operation:", error);
        });
    }
        
    } 
    // else {
    //   if (!phone) {
    //     setNumberError("Ange ett telefonnummer");
    //   }
    //   if (!email) {
    //     setEmailError("Vänligen ange e-post");
    //   }
    // }
  };

//   const handleNameChange = (event) => {
//     setName(event.target.value);
//   };

//   const handleBusinessChange = (event) => {
//     setBusiness_name(event.target.value);
//   };

//   const handleMessageChange = (event) => {
//     setMessage(event.target.value);
//   };

  const [formState, setFormState] = useState({
    name: "",
    business_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [err, setErr] = useState({
    nameErr: "",
    business_nameErr: "",
    emailErr: "",
    phoneErr: "",
    messageErr: "",
  });
  console.log(formState,"{uyuoiyu")

  const validateInput = (field, value, state, setState) => {
    const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
    const errors = { ...state.errors };

    if (htmlTagRegex.test(value)) {
      errors[field] = "HTML-taggar är inte tillåtna.";
    } else {
      errors[field] = "";
    }

    setState({
      ...state,
      [field]: value,
      errors,
    });
  };

  const handleObj = () => {};

  return (
    <div>
      <div className="background-contactus overflow-hidden">
        <div className="container my-auto">
          <h1 className="fs5-3 text-white text-center mb-3">Kontakta oss </h1>
          <div
            className="contactus-form card"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="600"
          >
            <div className="card-body">
              {loading && (
                <div className="position_loder">
                  <div className="spinner-border text-white" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              {/* <form onSubmit={handleSubmit}>
                <div className="row">
                  <div class="col-md-6 mb-4">
                    <label for="fullName" className="form-label">
                      Namn
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="fullName"
                      placeholder="Namn"
                      value={name}
                      onChange={handleNameChange}
                    />
                  </div>
                  <div class="col-md-6 mb-4">
                    <label for="busName" className="form-label">
                      Företag
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="busName"
                      placeholder="Företag"
                      value={business_name}
                      onChange={handleBusinessChange}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <label for="email" className="form-label">
                      E-post
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="E-post"
                      className="form-control"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && (
                      <div style={{ color: "red" }}>{emailError}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label for="" className="form-label">
                      Telefon
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Telefon"
                      value={phone}
                      onChange={handleNumberChange}
                    />
                    {numberError && (
                      <div style={{ color: "red" }}>{numberError}</div>
                    )}
                  </div>
                  <div className="col-md-12 mb-4">
                    <label for="message" className="form-label">
                      Skriv ditt meddelande här
                    </label>
                    <textarea
                      className="form-control"
                      id="message"
                      rows="3"
                      placeholder="Skriv ditt meddelande här"
                      value={message}
                      onChange={(e) => {
                        
                        const value = e.target.value;
                        const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
                        if (htmlTagRegex.test(value)) {
                          setMessageError("HTML-taggar är inte tillåtna.");
                        } else {
                          setMessageError("");
                          setMessage(value);
                        }
                      }}
                    />
                    {console.log('tanish', message)}
                    {messageError && (
                      <div style={{ color: "red" }}>{messageError}</div>
                    )}
                  </div>
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-warning">
                      Skicka
                    </button>
                  </div>
                </div>
              </form> */}
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <label htmlFor="fullName" className="form-label">
                      Namn
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      placeholder="Namn"
                      name="name"
                      value={formState.name}
                      onChange={handleInputChange}
                    />
                     {err.nameErr && (
                      <div style={{ color: "red" }}>{err.nameErr}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="busName" className="form-label">
                      Företag
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="busName"
                      placeholder="Företag"
                      name="business_name"
                      value={formState.business_name}
                      onChange={handleInputChange}
                    />
                     {err.business_nameErr && (
                      <div style={{ color: "red" }}>{err.business_nameErr}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="email" className="form-label">
                      E-post
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="E-post"
                      className="form-control"
                      name="email"
                      value={formState.email}
                      onChange={handleInputChange}
                    />
                    {err.emailErr && (
                      <div style={{ color: "red" }}>{err.emailErr}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-4">
                    <label htmlFor="phone" className="form-label">
                      Telefon
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Telefon"
                      name="phone"
                      value={formState.phone}
                      onChange={handleInputChange}
                    />
                    {err.phoneErr && (
                      <div style={{ color: "red" }}>{err.phoneErr}</div>
                    )}
                  </div>
                  <div className="col-md-12 mb-4">
                    <label htmlFor="message" className="form-label">
                      Skriv ditt meddelande här
                    </label>
                    <textarea
                      className="form-control"
                      id="message"
                      rows="3"
                      placeholder="Skriv ditt meddelande här"
                      name="message"
                      value={formState.message}
                      onChange={handleInputChange}
                    />
                    {err.messageErr && (
                      <div style={{ color: "red" }}>{err.messageErr}</div>
                    )}
                  </div>
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-warning">
                      Skicka
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container my-6 overflow-hidden">
          <div className="box-contact">
            <div className="width_contact mb-4">
              <div
                className="d-flex gap-3 align-items-center contactus-details"
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="600"
              >
                <div className="contact-icon-ring">
                  <RiBuilding4Line className="contact-icon black" />
                </div>
                <p className=" contact-detail text-center text-md-start">
                  Omni Telecom AB <br /> Södergatan 3, 211 34 Malmö{" "}
                </p>
              </div>
            </div>

            <div
              className="width_contact mb-4"
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="600"
            >
              <div className="d-flex gap-3 align-items-center contactus-details">
                <div className="contact-icon-ring">
                  <MdOutlineEmail className="contact-icon black" />
                </div>
                <p className=" contact-detail ">
                  {" "}
                  <Link
                    to="mailto:kontakt@omnitelecom.se"
                    className="text-dark"
                  >
                    kontakt@omnitelecom.se
                  </Link>{" "}
                </p>
              </div>
            </div>
            <div className="width_contact mb-4">
              <div
                className="d-flex gap-3 align-items-center contactus-details"
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="600"
              >
                <div className="contact-icon-ring">
                  <MdOutlinePhoneInTalk className="contact-icon black" />
                </div>
                <p className=" contact-detail">+46 40 93 31 00 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
