import React from 'react'
import ImageSection from './ImageSection'
import ServiceCard from './ServiceCard'
import PrincipleDPA from './PrincipleDPA'
import Testimonial from './Testimonial'


const Home = () => {
  return (
    <div>
      <ImageSection />
      <PrincipleDPA />
      <ServiceCard />
      <Testimonial />
    </div>
  )
}

export default Home
